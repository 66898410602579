import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Helse - Desarrollo de sitio web en Drupal con migración Wordpress" description="Proyecto de desarrollo web en Drupal 8 para centro de Fisioterapia Helse. En este proyecto se migraron los datos desde su antiguo Wordpress."/>
      <section className="jumbotron pt-3 pb-6 mb-0" style={{backgroundColor: '#E1F0C1'}}>
        <div className="hero-zone container">
          <div className="hero-text row justify-content-md-center">
            <div className="col-lg-6">
              <h1 className="font-weight-bold diplay-4">Helse</h1>
              <p className="h3">Desarrollo de sitio web y plataforma de gestión de pacientes y ejercicios en Drupal 8.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-3 bg-light text-left" id="features">
        <div className="container pb-5">
          <div className="row text-left">
            <div className="col-md-8 col-sm-12">
              <span className="badge badge-pill badge-secondary p-2 mr-2">Desarrollo Drupal</span>
              <span className="badge badge-pill badge-secondary p-2 m-2">Migración SEO</span>
              <span className="badge badge-pill badge-secondary p-2 m-2">Diseño Visual</span>
              <span className="badge badge-pill badge-secondary p-2 m-2 mb-4">UX</span>
              <h2>
                <strong className="text-left pt-3">Puntos clave</strong>
              </h2>
              <div className="lead pt-3">
                <strong className="texto-destacado">Migración desde Wordpress.</strong>
                <p>Se migraron todos los datos de pacientes, usuarios/contraseñas, entradas de blog, ejercicios y videos desde Wordpress. Se hizo un trabajo de transformación de la información de ejercicios para poderla migrar a Drupal y se migraron todos los videos alojados nativamente en Wordpress para pasarlos a Vimeo, actualizando contenido de los campos que los mostraban.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Gestión de ejercicios individualizados por paciente.</strong>
                <p>Uno de los mayores retos del proyecto fue el simplificar el portal interno de gestión de pacientes y ejercicios, para facilitar el proceso de creación de usuarios y ejercicios nuevos, así como la actualización masiva de los ejercicios de los pacientes existentes.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Paragraphs y layout totalmente modular.</strong>
                <p>Se desarrollaron 15 paragraphs con 7 modos de layout distintos para permitir al cliente crear todas sus páginas de contenido, dándole autonomía para crear nuevas páginas sin necesidad de depender de desarrollo y maquetación.</p>
              </div>
              <div className="lead pt-4 pb-0">
                <strong className="texto-destacado">Monitorización del uso del portal interno.</strong>
                <p>Desarrollo de herramienta para permitir al personal del centro monitorizar el uso de la intranet de ejercicios por parte de los pacientes para conocer qué pacientes siguen los ejercicios y cuáles no.</p>
              </div>
            </div>
            <div className="col-md-3 offset-md-0 offset-lg-1 col-sm-12">
              <div className="colaboradores">
                <h2 className="pt-lg-5">
                  <strong className="text-left pt-0">Colaboradores</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">En este proyecto colaboramos con <a href="https:tropicalestudio.com/" target="_blank" rel="noopener noreferrer">Tropical Estudio</a>, que hicieron la conceptualización del proyecto y toda la parte visual (Diseño visual y UX)</p>
              <div className="cliente-proyecto">
                <h2 className="pt-5">
                  <strong className="text-left pt-0">Cliente</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">Centro de Fisioterapia en Zaragoza que combina fisioterapia y ejercicio mediante la práctica dirigida de Pilates, running, entrenamiento personal, mejora del rendimiento y prevención.</p>
              <div className="btn-project">
                <a className="btn mr-4 btn-lg btn-outline-primary" href="https://helse.es/">Sitio web →</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="jumbotron pb-5 text-left bg-white pt-0 mt-n6" id="features">
        <div className="container">
          <div className="row text-left">
            <div className="col-md-12 col-12 col-sm-12">
              <h2 className="pt-4 pb-4">
                <strong>El proyecto en imágenes</strong>
              </h2>
            </div>
            <div className='embed-responsive embed-responsive-16by9'>
               <iframe className="project-video embed-responsive-item" title="Video" width={1200} height={674} src="https://player.vimeo.com/video/405491980?autoplay=1&loop=1&autopause=0&muted=1&background=1"></iframe>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProjectHelse
